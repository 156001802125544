document.getElementById('js-hamburger-button').onclick = toggleMenu
function toggleMenu () {
  document.getElementById('js-menu').classList.toggle('is-active')
  document.getElementById('js-hamburger-button').classList.toggle('is-active')
}

var flkty = new Flickity('.gallery-slider', {
  prevNextButtons: false,
  pageDots: false,
  draggable: false,
  adaptiveHeight: true
})

var setFullScreenImage = function (imageURL, id) {
  document.getElementById(id).style.backgroundImage = "url('" + imageURL + "')"
}


  var galleries = document.querySelectorAll('.gallery-tiles-desktop');
  if (galleries) {
    galleries.forEach(itm => {
      var galleryFlickity = new Flickity(itm, {
        adaptiveHeight: true,
        pageDots: false,
        wrapAround: true,
        imagesLoaded: true
      })
    })
  }

// var photos = document.querySelectorAll('.photo-button')

// for (var i = 0; i < photos.length; i++) {
//   // var id = photos[i].dataset.id
//   // var photo = photos[i].dataset.photo
//
//   photos[i].addEventListener('click', function (event) {
//     document.getElementById(this.dataset.id).style.backgroundImage = "url('" + this.dataset.photo + "')"
//     $('.photo-button').removeClass('is-active');
//     this.classList.toggle('is-active');
//   })
// }


//Fire the modaal
$('.swatch').modaal({
  type: 'image'
});

//Resize nav when scroll
$(window).scroll(function() {
  if ($(document).scrollTop() > 50) {
    $('header').addClass('shrink');
  } else {
    $('header').removeClass('shrink');
  }
});
